import React from 'react'

import {Container} from '../components/commons'
import SEO from '../components/seo'
import Task from '../components/task'
import Projects from '../components/projects'

import tasks from '../data/tasks.json'

const WorkSection = () => {
  const taskList = tasks.map(t => (
    <Task title={t.title} key={t.title}>{t.content}</Task>
  ))

  return (
    <>
      <SEO
        title="Work"
        keywords={['gatsby', 'portfolio', 'naveen kumar sangi', 'naveenkumarsangi']} />
      <Container id="tasks" className="tasks">
        <h1 className="title mb2">I&apos;m good at these things.</h1>
        <div className="row">{taskList}</div>
      </Container>
      <Container className="projects">
        <h1 className="title mb2">Showcase</h1>
        <div><Projects /></div>
      </Container>
    </>
  )
}

export default WorkSection
