import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import FontAwesome from 'react-fontawesome'

import ProjectData from '../data/projects.json'

const query = graphql`
  query ProjectImages {
    allFile(filter: {relativeDirectory: {eq: "projects" }}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Projects = () => (
  <StaticQuery
    query={query}
    render={data => {
      const hash = new Map()
      data.allFile.edges.map(({ node }) => node).concat(ProjectData)
        .forEach(item => hash.set(
          item.name.toLowerCase(),
          Object.assign(hash.get(item.name.toLowerCase()) || {}, item)
        ))
      const projectMap = Array.from(hash.values()).sort((a, b) => a.priority - b.priority)

      const projects = projectMap.map(node => {
        const image =
          <Image fluid={node.childImageSharp.fluid} />
        return (
          <div key={node.name} className="project">
            <div className="details">
              <h2 className="sub-title">{node.name}</h2>
              <p className="description">{node.description}</p>
              <div className="links">
                {node.github ? <a href={node.github} target="_blank" rel="noopener noreferrer" aria-label="github"><FontAwesome name="github" /></a> : null}
                {node.gitlab ? <a href={node.gitlab} target="_blank" rel="noopener noreferrer" aria-label="gitlab"><FontAwesome name="gitlab" /></a> : null}
                {node.website ? <a href={node.website} target="_blank" rel="noopener noreferrer" aria-label="globe"><FontAwesome name="globe" /></a> : null}
              </div>
            </div>
            <div className="screenies">
              {node.website ? <a href={node.website} target="_blank" rel="noopener noreferrer">{image}</a> : image}
            </div>
          </div>
        )
      })

      return (
        <div>{projects}</div>
      )
    }}
  >
  </StaticQuery>
)

export default Projects
