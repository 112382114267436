import React, { Component } from 'react';

class Task extends Component {
  render() {
    const { title, children } = this.props;
    return (
      <div className="col-4 flex flex-column items-center">
        <div className="sub-title">{title}</div>
        <p className="content">{children}</p>
      </div>
    );
  }
}

export default Task;
